import * as React from 'react'
import Layout from '../../../layout'
import LeaderboardsPage from '../../../pagesComponents/leaderboards'

const Leaderboards = ({ id }) => {
	return (
		<Layout title="Group Leaderboard">
			<LeaderboardsPage groupId={id} />
		</Layout>
	)
}

export default Leaderboards
